import React from "react";
import logo from "../Assets/Logos/rmcLogo.png";
import { useNavigate } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { IoLocation } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";
import UdemyCourse from "../docs/SinghRMC_DOC.pdf";

const UserFooter = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <footer className="bg-cover bg-center py-8 bg-[#1E1E1E] shadow-xl border-t border-gray-300">
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-6 text-white">
          <div className="space-y-3">
            <img src={logo} alt="Logo" className="h-auto w-32 mb-3" />

            <div className="flex gap-3 font-bold items-center text-[14px] mb-3  tracking-wider text-[#333333] pl-3">
              {/* Singh House, Nimeishapur, Bhagatpur, Cuttack */}
              <a
                href="https://www.facebook.com/profile.php?id=61563921037291"
                target="_blank"
              >
                <FaFacebook color="#0866ff" size={20} />
              </a>
              {/* <a href="https://www.instagram.com/" target="_blank">
                <GrInstagram color="#fe0269" size={20} />
              </a> */}
              <a href=" https://x.com/singhRMC_Infra" target="_blank">
                <FaTwitter color="#1da1f2" size={20} />
              </a>
              <a
                href="https://www.youtube.com/@aSinghRmcInfraPvtLtdReadyMixco"
                target="_blank"
              >
                <FaYoutube color="#ff0000" size={20} />
              </a>
              <a
                href=" https://www.instagram.com/singhrmcinfra?igsh=YzljYTk1ODg3Zg=="
                target="_blank"
              >
                <GrInstagram size={20} color="#e6407b" />
              </a>
            </div>
            <p className="font-medium text-sm md:text-base tracking-wider md:text-start  text-secondaryTextColor">
              At Singh RMC, we specialize in providing high-quality Pavement
              Quality Concrete (PQC) and Dry Lean Concrete (DLC) solutions.
            </p>
          </div>

          <div className="flex md:justify-center md:text-start  w-full tracking-wider text-secondaryTextColor">
            <div className="space-y-3">
              <h5 className="text-lg font-bold mb-1">
                <span className="pb-1 border-b-2 border-primaryColor">
                  Quick Links
                </span>
              </h5>
              <div className="space-y-2">
                <div
                  onClick={() => handleNavigation("/")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  Home
                </div>

                <div
                  // onClick={() => handleNavigation("/services")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  <a href="#Services">Services</a>
                </div>

                <div
                  onClick={() => handleNavigation("/about-us")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  About Us
                </div>

                <div
                  onClick={() => handleNavigation("/contact-us")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  Contact Us
                </div>
{/*                 <div
                  // onClick={() => handleNavigation("/contact-us")}
                  className="hover:text-primaryColor cursor-pointer"
                >
                  <a href={UdemyCourse} download="SinghRMC_DOC.pdf">
                    Download Brochure{" "}
                  </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="space-y-3 tracking-wider md:text-start  text-secondaryTextColor">
            <h5 className="text-lg font-bold mb-1">
              <span className="pb-1 border-b-2 border-primaryColor">
                Contact Us
              </span>
            </h5>

            <div className="space-y-2">
              <div className="flex ">
                <BiSolidPhoneCall color="#28B40E" size={25} />{" "}
                <span className="ml-2">+91-9437710574</span>
              </div>

              <div className="flex items-center ">
                <RiWhatsappFill color="#28B40E" size={20} />{" "}
                <span className="ml-2">+91-9776170355</span>
              </div>

              <div className="flex items-center ">
                <IoMdMail color="#28B40E" size={20} />{" "}
                <span className="ml-2">info@singhrmcinfra.com</span>
              </div>

              <div className="flex">
                <IoLocation color="#28B40E" size={28} className="mt-[]" />
                <span className="ml-2">
                  Singh House, Nimeishapur, Bhagatpur, Cuttack, Odisha
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="flex flex-wrap justify-between items-center w-full py-2 px-6 text-sm bg-[#0a0b0b] text-white tracking-wider">
        <div>
          Privacy Policy | Terms & Conditions
          <span> &nbsp;© copyright {currentYear}, </span>
          <span className="text-primaryColor font-semibold">
            Singh RMC Infra
          </span>
        </div>

        <div className="mr-4">
          <span
            className="text-[#fe8740] cursor-pointer"
            onClick={() => window.open("https://webbocket.com/")}
          >
            Web_Bocket PVT LTD.&nbsp;
          </span>
          All Rights Reserved.
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFooter;
